import React from "react"
import ReactMarkdown from 'react-markdown'
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Container, Row, Col } from "react-bootstrap"
import Img from "gatsby-image"
import SEO from 'components/seo'
import "./templates.scss"
export default function AgenceTemplate({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { mdx } = data; // data.mdx holds your post data
  const { frontmatter, body, excerpt } = mdx;

  return (
    <>
      <SEO title={frontmatter.title} description={excerpt}/>
        <Container fluid className="container">
            <Row className="justify-content-md-center section-section">        
              <Col md="3">
                <div className="portrait">
                  <Img fluid={frontmatter.portrait.childImageSharp.fluid}/>
                </div>
              </Col>
              <Col md="9">
                <MDXRenderer className="pages-content">
                  {body}
                </MDXRenderer>
              </Col>
            </Row>
            {frontmatter.sections.map((section, i) => (
              <Row key={i} className="justify-content-md-center section-section">
                <Col md="12">
                  <h3>{section.heading}</h3>
                </Col>
                {i % 2 === 0 && (
                  <>
                    <Col md="8">
                      <ReactMarkdown className="pages-content">
                        {section.text}
                      </ReactMarkdown>
                    </Col>
                    <Col md="4">
                      <div className="section-image">
                        <Img fluid={section.image.childImageSharp.fluid}/>
                      </div>
                    </Col>
                  </>
                )}
                {i % 2 !== 0 && (
                  <>
                    <Col md="4">
                      <div className="section-image">
                        <Img fluid={section.image.childImageSharp.fluid}/>
                      </div>
                    </Col>
                    <Col md="8">
                      <ReactMarkdown className="pages-content">
                        {section.text}
                      </ReactMarkdown>
                    </Col>
                  </>
                )}
              </Row>
            ))}
        </Container>
      
    </>
  )
}



export const pageQuery = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      excerpt
      frontmatter {
        title
        portrait {
          childImageSharp {
            fluid(maxWidth: 250) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        sections {
          heading
          text
          image {
            childImageSharp {
              fluid(maxWidth: 450) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
    }
  }
`